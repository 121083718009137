@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

.anonymous {
  color: #75c1d5;
  text-decoration: underline;
  font-weight: 800;
}

.decoration-none {
  text-decoration: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* 300 */

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Light.otf");
  font-weight: 300;
}

/* 400 */

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Regular.otf");
}

/* 500 */

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Medium.otf");
  font-weight: 500;
}

/* 600 */

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-SemiBold.otf");
  font-weight: 600;
}

/* 700 */

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Bold.otf");
  font-weight: bold;
}

/* 800 */

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-ExtraBold.otf");
  font-weight: 800;
}

/* 900 */

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Black.otf");
  font-weight: 900;
}

* {
  font-family: "Poppins", "Inter", sans-serif;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-25px);
    -ms-transform: translateY(-25px);
    transform: translateY(-25px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 1s;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 1s;
}
